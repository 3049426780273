import { environmentApi } from "@store/services/apiSingleton";
import { CurrencyFormatMode, PaymentProvider, UIVersion } from "@utils/constants/routes";

export interface IInitEnvironmentResponse {
  acf: {
    REACT_APP_PRIMARY_COLOR: string;
    REACT_APP_SECONDARY_COLOR: string;
    REACT_APP_TEXT_PRIMARY_COLOR: string;
    REACT_APP_TEXT_SECONDARY_COLOR: string;
    REACT_APP_INPUT_BORDER_COLOR: string;
    REACT_APP_INPUT_BACKGROUND_COLOR: string;
    REACT_APP_INPUT_PLACEHOLDER_COLOR: string;
    REACT_APP_INPUT_TEXT_COLOR: string;
    REACT_APP_INPUT_FOCUS_BORDER_COLOR: string;
    REACT_APP_ERROR_COLOR: string;
    REACT_APP_MENU_BACKGROUND_COLOR: string;
    REACT_APP_MENU_TEXT_COLOR: string;
    REACT_APP_ELEMENT_BACKGROUND_PRIMARY: string;
    REACT_APP_ELEMENT_BACKGROUND_SECONDARY: string;
    REACT_APP_ELEMENT_BACKGROUND_DARK: string;
    REACT_APP_BACKGROUND_COLOR: string;
    REACT_APP_LINK_COLOR: string;
    REACT_APP_SECTION_BACKGROUND_COLOR: string;
    REACT_APP_SHADOW_COLOR: string;
    REACT_APP_SUBTEXT_COLOR: string;
    REACT_APP_DELIMITTER_LINE_COLOR: string;
    REACT_APP_SVG_ICONS_PRIMARY: string;
    REACT_APP_SVG_ICONS_SECONDRY: string;
    REACT_APP_SEARCH_BUTTON_BACKGROUND: string;
    site_logo: string;
    header_background: string;
    footer_background: string;
    footer_font_color: string;
    react_app_cabin_select_api_username: string;
    react_app_cabin_select_api_password: string;
    react_app_cabin_select_api_agency: string;
    react_app_cabin_select_search_domain: string;
    react_app_cabin_select_token_domain: string;
    react_app_cabin_select_pos_domain: string;
    REACT_APP_CABIN_SELECT_API: string;
    REACT_APP_CABIN_SELECT_APP_LANGUAGE: string;
    react_app_cabin_select_payment_domain: string;
    react_app_cabin_select_date_format: string;
    react_app_cabin_select_api_language: string;
    react_app_cabin_select_authorize_api_login_id: string;
    react_app_cabin_select_authorize_client_key: string;
    react_app_cabin_select_table_view: string;
    REACT_APP_CABIN_SELECT_API_AGENT: string;
    REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE: string;
    REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST: string;
    REACT_APP_PAYMENT_API_PATH: string;
    REACT_APP_SEARCH_ITEM_IMAGE_SOURCE: "ship" | "cruise";
    REACT_APP_PRIVACY_POLICY: string;
    REACT_APP_TERMS_AND_CONDITIONS: string;
    REACT_APP_LIABILITY_AND_CANCELLATION_POLICY: string;
    REACT_APP_PARTICIPANT_RELEASE_AGREEMENT: string;
    REACT_APP_SUPPLEMENTAL_TERMS: string;
    REACT_APP_GUEST_TICKET_CONTRACT: string;
    REACT_APP_SUBTRACT_GFT: string;
    REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS: string;
    REACT_APP_SHOW_CANCELLATION_POLICY: string;
    REACT_APP_BREADCRUMBS_TEXT: string;
    REACT_APP_SHOW_PRICING_BREAKDOWN: string | boolean;
    REACT_APP_CHECK_EMAIL_UNIQUENESS: string | boolean;
    REACT_APP_SHOW_PAGINATION: boolean;
    REACT_APP_PAGINATION_TYPE: string;
    REACT_APP_PAGINATION_COUNT: number;
    REACT_APP_SKIP_PAYMENT_STEP: string | boolean;
    REACT_APP_HEADER_SHOW_CURRENCY_CODE: string;
    REACT_APP_HEADER_SHOW_LANGUAGE_PICTURE: string;
    REACT_APP_HEADER_BACKGROUND_COLOR: string;
    REACT_APP_HEADER_TEXT_COLOR: string;
    REACT_APP_HEADER_TELEPHONE_NUMBER: string;
    REACT_APP_HEADER_LOGO_PICTURE: string;
    REACT_APP_FOOTER_BACKGROUND_COLOR: string;
    REACT_APP_FOOTER_TEXT_COLOR: string;
    REACT_APP_FOOTER_EMAIL_ADDRESS: string;
    REACT_APP_FOOTER_ADDRESS: string;
    REACT_APP_GFT_ADDITIONAL_TEXT: string;
    REACT_APP_DESTINATION_INPUT_PLACEHOLDER: string;
    REACT_APP_BIN_RESTRICTION: string;
    REACT_APP_FAVICON: string;
    REACT_APP_HEADER_LOGO_HEIGHT: string;
    REACT_APP_AIRWALLEX_ENV: string;
    REACT_APP_NUMBER_STATEROOM_PAGE_DESCRIPTION: string;
    REACT_APP_CABIN_SELECT_PAYMENT_PROVIDER: PaymentProvider;
    REACT_APP_UI_VERSION: UIVersion;
    REACT_APP_DECK_LEGEND: string;
    REACT_APP_CURRENCY_FORMAT_MODE: CurrencyFormatMode;
  };
}

export const EnvironmentAPI = environmentApi.injectEndpoints({
  endpoints: (builder) => ({
    initEnvironment: builder.query<IInitEnvironmentResponse, null>({
      query: () => ({
        url: "/wp-json/acf/v3/options/options/",
        method: "GET",
      }),
    }),
  }),
});
